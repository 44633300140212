import { FormProps, WidgetProps } from '@rjsf/core';
import React from 'react';
import Form from '@rjsf/material-ui';
import { CardActions } from '@bb-ui/react-library/dist/components/CardActions';
import { CardContent, Chip, Input, IconButton, InputLabel } from '@bb-ui/react-library';
import { BulletedListDefault, Editor } from '@bb-ui/icons/dist/small';
import ReactJson from 'react-json-view';
import { JobCard, JobCardProps } from './JobCard';
import { Paste } from '@bb-ui/icons/dist/medium';

export interface JobFormCardProps extends Omit<JobCardProps, 'content'>, FormProps<any> {}

const CopyableTextWidget: React.FC<WidgetProps> = ({ id, label, required, value, readonly }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value || '');
  };

  const copyableFields = ['TenantId', 'url', 'entryCode'];
  const isCopyable = copyableFields.includes(label);

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input id={id} value={value || ''} style={{ flex: 1 }} disabled={readonly} />
        {isCopyable && (
          <IconButton type="button" onClick={handleCopy} style={{ marginLeft: '8px' }}>
            <Paste />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export const JobFormCard: React.FC<JobFormCardProps> = (props) => {
  const [rawShown, setRawShown] = React.useState(false);
  const { uiSchema, formData, actions, ...rest } = props;

  const updatedUiSchema = Object.keys(formData || {}).reduce((acc: { [key: string]: any }, key) => {
    acc[key] = { 'ui:widget': 'copyableText' };
    return acc;
  }, {});

  return (
    <JobCard
      content={
        <CardContent>
          {rawShown ? (
            <ReactJson src={formData} name={false} enableClipboard={true} />
          ) : (
            <Form
              uiSchema={{
                ...uiSchema,
                ...updatedUiSchema,
                'ui:submitButtonOptions': { norender: true },
                'ui:readonly': true,
              }}
              {...rest}
              formData={formData}
              widgets={{ copyableText: CopyableTextWidget }}
            />
          )}
        </CardContent>
      }
      actions={
        <CardActions>
          <Chip
            label={`View ${rawShown ? 'List' : 'JSON'}`}
            onClick={() => setRawShown(!rawShown)}
            icon={rawShown ? <BulletedListDefault /> : <Editor />}
          />
          {actions}
        </CardActions>
      }
      {...rest}
    />
  );
};
