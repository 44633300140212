import {
  LinearProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@bb-ui/react-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IJobConfig, ListJobsResponseBody } from '@lct-jobs/core';
import { useEffect } from 'react';
import useRestApi from '../../hooks/useRestApi';
import { apiUrl } from '../../utils/apiUrl';
import { JobRow } from './JobRow';
import { useTenantContext } from '../../contexts/TenantContext';
import { useSnackbar } from '../../hooks/useSnackbar';

const commonJobId = ['OnetimeLogin', 'UpdateTenantState', 'UpgradeLearnInstance'];

export const JobList: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant, tenantId } = useTenantContext();

  const jobApi = useRestApi<ListJobsResponseBody>(
    apiUrl('lct', `tenants/${tenantId}/jobManager/jobs`),
  );

  const childTenantId = tenant?.tenantChildren?.[0];

  const jobApiChildTenant = useRestApi<ListJobsResponseBody>(
    apiUrl('lct', `tenants/${childTenantId}/jobManager/jobs`),
    { manual: true },
  );

  const [jobs, setJobs] = React.useState<IJobConfig[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [hasFetchedChildJobs, setHasFetchedChildJobs] = React.useState<boolean>(false);

  const { data, error } = jobApi;

  useEffect(() => {
    if (childTenantId && tenant?.tenantType === 'Learn' && !hasFetchedChildJobs) {
      jobApiChildTenant.fetch();
      setHasFetchedChildJobs(true);
    }
  }, [childTenantId, hasFetchedChildJobs, jobApiChildTenant, tenant]);

  useEffect(() => {
    const combinedJobs: IJobConfig[] = [
      ...(data?.jobs ?? []),
      ...(jobApiChildTenant.data?.jobs ?? []),
    ];

    const commonJobs = combinedJobs.filter((job) => commonJobId.includes(job.jobId));
    const deleteJobs = combinedJobs.filter((job) => job.jobId.toLowerCase().startsWith('delete'));
    const otherJobs = combinedJobs.filter(
      (job) => !commonJobId.includes(job.jobId) && !job.jobId.toLowerCase().startsWith('delete'),
    );

    setJobs([...commonJobs, ...otherJobs, ...deleteJobs]);
  }, [data, jobApiChildTenant.data, tenant]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to load Jobs: ${error?.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredJobs = jobs.filter(
    (job) =>
      job.jobId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.jobName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      job.jobDescription.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div>
      <TextField
        floatingLabel={true}
        label={t('jobService.searchJobs')}
        value={searchQuery}
        onChange={handleSearchChange}
        margin="normal"
      />
      {jobApi.loading || jobApiChildTenant.loading ? (
        <LinearProgress ariaLabel="Loading Jobs" />
      ) : null}
      <Table>
        <TableHead>
          <TableRow key="$HEADER">
            <TableCell>{t('jobService.name')}</TableCell>
            <TableCell>{t('jobService.domain')}</TableCell>
            <TableCell>{t('jobService.description')}</TableCell>
            <TableCell>{t('jobService.submitJob')}</TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          {filteredJobs.map((job) => (
            <JobRow key={job.jobId} job={job} tenantId={tenantId} siteTenantId={childTenantId} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
