import {
  LinearProgress,
  PrimaryButton,
  Table,
  TableContainer,
  Typography,
} from '@bb-ui/react-library';
import * as React from 'react';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { useTranslation } from 'react-i18next';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import { JobExecutionRow } from './JobExecutionRow';
import { LoadingIndicator } from '../LoadingIndicator';
import { useStyles } from '../JobList';
import { JobExecutionContext } from '../../contexts/JobExecutionContext';

export const JobExecutionTable: React.FC = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const c = React.useContext(JobExecutionContext);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (
        c.executions.some((execution) =>
          [
            'IN_PROGRESS',
            'PENDING',
            'QUEUED',
            'CANCELLATION_REQUESTED',
            'EXECUTION_TIMEOUT_PENDING',
          ].includes(execution.status),
        )
      ) {
        c.refresh();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [c.executions, c]);

  return (
    <TableContainer>
      {c.loading ? <LinearProgress ariaLabel="Loading Jobs" /> : null}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {c.executions.map((execution) => (
            <JobExecutionRow key={execution.executionId} jobExecution={execution} />
          ))}
        </TableBody>
      </Table>

      {c.nextPageToken ? (
        <div className={classes.loadMoreButton}>
          {c.loading ? (
            <LoadingIndicator data-testid="load-more-loading" />
          ) : (
            <PrimaryButton onClick={c.loadMore}>{t('jobService.loadMore')}</PrimaryButton>
          )}
        </div>
      ) : (
        <Typography className={classes.loadMoreButton} data-testid="jobs-list-no-more-data">
          {t('jobService.noMoreData')}
        </Typography>
      )}
    </TableContainer>
  );
};
