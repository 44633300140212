import React from 'react';
import { Grid } from '@material-ui/core';
import { useLocation, useParams } from 'react-router';
import { JobInputBox } from './components/JobInputBox';
import { JobOutputBox } from './components/JobOutputBox';
import { useJobExecutionContext } from '../../contexts/JobExecutionContext';
import { JobErrorBox } from './components/JobErrorBox';
import { ExecutionBox } from './components/ExecutionBox';
import { RegistrationBox } from './components/RegistrationBox';
import { SummaryWidget } from './components/SummaryWidget';
import { JobChildrenBox } from './components/JobChildrenBox';
import { JobLogsBox } from './components/JobLogsBox';
import { ExecutionDetailResponseBody } from '@lct-jobs/core';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useTenantContext } from 'contexts/TenantContext';

export const JobExecutionDetail: React.FC = () => {
  const { executionId } = useParams<{ executionId: string }>();
  const { tenantId, tenant } = useTenantContext();
  const { setExecutionId, setTenantId } = useJobExecutionContext();
  setExecutionId(executionId);

  const jobExecutionDetails = useRestApi<ExecutionDetailResponseBody>(
    apiUrl('lct', `tenants/${tenantId}/jobManager/executions/${executionId}`),
  );

  const siteJobExecutionDetails = useRestApi<ExecutionDetailResponseBody>(
    apiUrl('lct', `tenants/${tenant?.tenantChildren?.[0]}/jobManager/executions/${executionId}`),
  );

  let jobExecutionData: ExecutionDetailResponseBody | undefined;
  let siteExecution: boolean = false;

  jobExecutionData = siteJobExecutionDetails.data;
  if (jobExecutionDetails.data) {
    jobExecutionData = jobExecutionDetails.data;
    setTenantId(tenantId);
  } else if (siteJobExecutionDetails.data && tenant?.tenantChildren) {
    jobExecutionData = siteJobExecutionDetails.data;
    setTenantId(tenant?.tenantChildren?.[0]);
    siteExecution = true;
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (
        [
          'IN_PROGRESS',
          'PENDING',
          'QUEUED',
          'CANCELLATION_REQUESTED',
          'EXECUTION_TIMEOUT_PENDING',
        ].includes(jobExecutionData!.status)
      ) {
        if (siteExecution) {
          siteJobExecutionDetails.fetch();
        } else {
          jobExecutionDetails.fetch();
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [jobExecutionData, jobExecutionDetails, siteExecution, siteJobExecutionDetails]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <SummaryWidget jobExecution={jobExecutionData} />
      </Grid>
      <JobExecutionDetailGridItem>
        <RegistrationBox
          jobExecution={jobExecutionData}
          detailsLoading={jobExecutionDetails.loading}
          siteDetailsLoading={siteJobExecutionDetails.loading}
        />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <ExecutionBox
          jobExecution={jobExecutionData}
          detailsLoading={jobExecutionDetails.loading}
          siteDetailsLoading={siteJobExecutionDetails.loading}
        />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobChildrenBox
          jobExecution={jobExecutionData}
          detailsLoading={jobExecutionDetails.loading}
          siteDetailsLoading={siteJobExecutionDetails.loading}
        />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobLogsBox jobExecution={jobExecutionData} />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobInputBox jobExecution={jobExecutionData} />
      </JobExecutionDetailGridItem>
      <JobExecutionDetailGridItem>
        <JobOutputBox jobExecution={jobExecutionData} />
      </JobExecutionDetailGridItem>
      {jobExecutionData?.exception && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <JobErrorBox jobExecution={jobExecutionData} />
        </Grid>
      )}
    </Grid>
  );
};

const JobExecutionDetailGridItem: React.FC = (props) => (
  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
    {props.children}
  </Grid>
);
