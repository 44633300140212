import React from 'react';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import { JobFormCard } from './basic/JobFormCard';
import { ExecutionDetailResponseBody } from '@lct-jobs/core';

export interface JobInputProps {
  jobExecution?: ExecutionDetailResponseBody;
}

export const JobInputBox: React.FC<JobInputProps> = (props) => {
  const execution = props.jobExecution;
  return (
    <JobFormCard
      title="Job Input"
      schema={execution?.jobConfig.inputSchema ?? {}}
      uiSchema={execution?.jobConfig.inputUiSchema}
      formData={execution?.jobInput}
    />
  );
};
