import React from 'react';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ExecutionDetailResponseBody, JobExecutionDto } from '@lct-jobs/core';
import { Divider, ListItemIcon } from '@bb-ui/react-library';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { useHistory } from 'react-router-dom';
import { JobCardList } from './basic/JobCardList';
import { JobStatusIcon } from '../../JobStatusIcon/JobStatusIcon';

export interface JobChildrenProps {
  jobExecution?: ExecutionDetailResponseBody;
  detailsLoading?: boolean;
  siteDetailsLoading?: boolean;
}

const JobChildItem: React.FC<{ child: JobExecutionDto }> = (props) => {
  const history = useHistory();
  return (
    <ListItem
      button
      onClick={() =>
        history.push(`/tenants/${props.child.tenantId}/executions/${props.child.executionId}`)
      }
    >
      <ListItemIcon>
        <JobStatusIcon status={props.child.status} />
      </ListItemIcon>
      <ListItemText primary={`Job ID: ${props.child.jobId}`} secondary={props.child.status} />
    </ListItem>
  );
};

export const JobChildrenBox: React.FC<JobChildrenProps> = (props) => {
  const execution = props.jobExecution;
  const loadingDetails = props.detailsLoading;
  const siteDetailsLoading = props.siteDetailsLoading;
  const children = execution?.children || [];
  const childrenItems = children.flatMap((child) => [<JobChildItem child={child} />, <Divider />]);
  childrenItems.pop(); // remove last divider

  return (
    <JobCardList loading={loadingDetails || siteDetailsLoading} title="Child Job Executions">
      {childrenItems}
    </JobCardList>
    // todo: add pagination
  );
};
