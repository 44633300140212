import { EditTool } from '@bb-ui/icons/dist/small';
import { IconButton } from '@bb-ui/react-library';
import * as React from 'react';
import { EditGroupDialog } from 'dialogs/EditGroupDialog';
import { FoundationsGroup } from 'pages/TenantIdp/GroupMappingCard/GroupMapping.types';

export interface EditGroupButtonProps {
  group: FoundationsGroup;
}

export const EditGroupButton: React.FunctionComponent<EditGroupButtonProps> = ({ group }) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  return (
    <>
      <IconButton
        aria-label="Edit Foundations Group"
        onClick={() => setEditDialogOpen(true)}
        data-testid={`edit-group-button-${group.name}`}
      >
        <EditTool />
      </IconButton>
      <EditGroupDialog
        id="edit-group-dialog"
        open={editDialogOpen}
        group={group}
        onClose={() => setEditDialogOpen(false)}
      />
    </>
  );
};
