import { CardHeader, createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LearnInstance } from '@bb-learn-instances-service/common';
import { LearnSiteInformationItem } from './SiteInformationItem';
import { useTenantContext } from '../../contexts/TenantContext';
import { useRestApi } from '../../hooks/useRestApi';
import { apiUrl } from '../../utils/apiUrl';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginBottom: '14px',
    },
    section: {
      marginBottom: '45px',
    },
    pageTitleContainer: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export const SiteInformation: React.FC = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  let tenantId = tenant?.id;
  const childTenantId = tenant?.tenantChildren?.[0]
  if (childTenantId) {
    tenantId = childTenantId
  }
  const instanceApi = useRestApi<LearnInstance>(
    apiUrl('lct', `tenants/${tenantId}/instances/learnInstances`),
  );
  const siteInformation = instanceApi.data;
  return (
    <div data-testid="learn-site-information-page">
      <div className={classes.section}>
        <CardHeader
          title={t('learnSiteInformation.pageTitle')}
          titleTypographyProps={{
            color: 'textSecondary',
            component: 'h3' as any,
            variant: 'h4',
          }}
          className={classes.heading}
        />
        {instanceApi.loading && <div>Loading...</div>}
        {instanceApi.error && <div>Error: {instanceApi.error.message}</div>}
        {siteInformation && (
          <div>
            <LearnSiteInformationItem
              title={t('learnSiteInformation.clientId')}
              value={siteInformation.ClientId}
              key="Captain ID"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.status')}
              value={siteInformation.Status}
              key="Status"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.fleetName')}
              value={siteInformation.FleetId}
              key="Fleet name"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.timezone')}
              value={siteInformation.Timezone}
              key="Timezone"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.installationId')}
              value={siteInformation.InstallationId}
              key="InstallationId"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.region')}
              value={siteInformation.Region}
              key="Region"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.created')}
              value={new Date(Number(siteInformation.created) * 1000)
                .toLocaleString('en-GB')
                .replace(',', '')}
              key="created"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.url')}
              value={siteInformation.Url}
              key="Url"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.updated')}
              value={new Date(Number(siteInformation.created) * 1000)
                .toLocaleString('en-GB')
                .replace(',', '')}
              key="updated"
            />
            <LearnSiteInformationItem
              title={t('learnSiteInformation.tenantId')}
              value={siteInformation.TenantId}
              key="TenantId"
            />
          </div>
        )}
      </div>
    </div>
  );
};
