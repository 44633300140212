import * as React from 'react';
import { ACTIVE_STATUSES, PENDING_STATUSES, Status, TERMINAL_STATUSES } from '@lct-jobs/core';
import { StatusApproval, StatusAway, StatusDisapproval } from '@bb-ui/icons/dist/medium';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { useTranslation } from 'react-i18next';

export const JobStatusIcon: React.FC<{ status?: Status }> = (props) => {
  const { t } = useTranslation();
  let status;
  if (TERMINAL_STATUSES.includes(props.status as any)) {
    if (props.status === Status.COMPLETED) {
      status = <StatusApproval />;
    } else {
      status = <StatusDisapproval color="error" />;
    }
  } else if (ACTIVE_STATUSES.includes(props.status as any)) {
    status = (
      <CircularProgress ariaLabel={t('global.loadingIndicator.loading').toString()} size="small" />
    );
  } else if (PENDING_STATUSES.includes(props.status as any)) {
    status = <StatusAway />;
  }
  return (
    <div
      style={{
        paddingLeft: '8px',
      }}
    >
      {status}
    </div>
  );
};
