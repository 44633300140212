import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, makeStyles, createStyles, Typography } from '@bb-ui/react-library';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { Table, TableBody, TableRow, TableCell } from '@bb-ui/react-library/dist/components';
import { Checkbox } from '@bb-ui/react-library/dist/components/Checkbox';
import { SearchBox } from '@bb-ui/react-library/dist/components/SearchBox';
import { useSnackbar } from 'hooks/useSnackbar';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { orderBy, chunk } from 'lodash';

const styles = (theme: Theme) =>
  createStyles({
    addGroupInput: {
      margin: theme.spacing(2, 0, 1),
    },
    tableContainer: {
      maxHeight: '75vh',
      overflowY: 'auto',
    },
    row: {
      display: 'flex',
    },
    clickableCell: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: '28rem',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  });

export const useStyles = makeStyles(styles);

export interface EditGroupDialogProps extends DialogProps {
  id: string;
  open: boolean;
  group: any;
  onClose: () => void;
}

export const EditGroupDialog: React.FunctionComponent<EditGroupDialogProps> = ({
  id,
  open,
  group,
  onClose,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { data, fetch } = useRestApi(apiUrl('authz', 'permissions'));
  const {
    doPatch,
    loadingRequests,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = useRestApi(apiUrl('sso', `fndsGroups`), { manual: true });

  const permissions = React.useMemo<string[]>(() => data?.permissions ?? [], [data]);
  const [selectedPermissions, setSelectedPermissions] = React.useState<Set<string>>(new Set());
  const [showPermissionsModal, setShowPermissionsModal] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    if (open) {
      fetch();
      setSelectedPermissions(new Set(group?.permissions || []));
    }
  }, [open, group, fetch]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('editGroupDialog.updateSucceeded', { name: group.name }), {
        variant: 'success',
      });
      clearSucceededRequests();
      onClose();
    }
  }, [succeededRequests, clearSucceededRequests, enqueueSnackbar, onClose, group.name, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('editGroupDialog.updateFailed', {
          message: failedRequests[0].error.message,
          name: group.name,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
    }
  }, [failedRequests, clearFailedRequests, enqueueSnackbar, group.name, t]);

  const filteredPermissions = React.useMemo(() => {
    const sortedPermissions = orderBy(permissions, [(perm: string) => perm.toLowerCase()], ['asc']);
    return chunk(
      sortedPermissions.filter((perm: string) =>
        perm.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
      4,
    );
  }, [searchTerm, permissions]);

  const togglePermission = (perm: string) => {
    setSelectedPermissions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(perm)) {
        newSet.delete(perm);
      } else {
        newSet.add(perm);
      }
      return newSet;
    });
  };

  const updateGroup = () => {
    doPatch(group.id, {
      permissions: Array.from(selectedPermissions),
    });
  };

  return (
    <Dialog id={id} open={open} onClose={onClose} scroll="body">
      <DialogTitle onClose={onClose}>{t('editGroupDialog.title')}</DialogTitle>
      <DialogContent>
        <TextField
          className={classes.addGroupInput}
          label={t('editGroupDialog.groupName')}
          value={group?.name || ''}
          fullWidth
          required
          disabled
        />
        <TextField
          className={classes.addGroupInput}
          label={t('editGroupDialog.groupType')}
          value={group?.type || ''}
          fullWidth
          required
          disabled
        />
        <TextField
          className={classes.addGroupInput}
          label={t('editGroupDialog.groupDesc')}
          value={group?.description || ''}
          fullWidth
          multiline
          required
          disabled
        />
        <DefaultButton onClick={() => setShowPermissionsModal(true)}>
          {`${t('editGroupDialog.selectPermissions')} (${selectedPermissions.size})`}
        </DefaultButton>
        <Typography variant="body2" color="textSecondary">
          {selectedPermissions.size > 0
            ? `${selectedPermissions.size} ${t('editGroupDialog.permissionsSelected')}`
            : t('editGroupDialog.noPermissionsSelected')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <DefaultButton onClick={onClose}>{t('global.cancel')}</DefaultButton>
        <PrimaryButton onClick={updateGroup} disabled={loadingRequests}>
          {t('global.save')}
        </PrimaryButton>
      </DialogActions>
      <Dialog
        open={showPermissionsModal}
        onClose={() => setShowPermissionsModal(false)}
        maxWidth="xl"
      >
        <DialogTitle onClose={() => setShowPermissionsModal(false)}>
          {`${t('editGroupDialog.selectPermissions')} (${selectedPermissions.size})`}
        </DialogTitle>
        <DialogContent className={classes.tableContainer}>
          <SearchBox
            inputId="search-permissions"
            label={t('editGroupDialog.searchPermissions')}
            value={searchTerm}
            onChange={setSearchTerm}
            onSearch={setSearchTerm}
          />
          <Table>
            <TableBody>
              {filteredPermissions.map((row, rowIndex) => (
                <TableRow key={rowIndex} className={classes.row}>
                  {row.map((perm) => (
                    <TableCell
                      key={perm}
                      className={classes.clickableCell}
                      onClick={() => togglePermission(perm)}
                    >
                      <Checkbox
                        checked={selectedPermissions.has(perm)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => togglePermission(perm)}
                      />
                      {perm}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};
