import React, { ReactElement, useEffect, useState } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  makeStyles,
  createStyles,
  IconButton,
  Link,
  CircularProgress,
  CardHeader,
} from '@bb-ui/react-library';
import { Visible } from '@bb-ui/icons/dist/small';
import { useTranslation } from 'react-i18next';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { UseRestApiResult } from 'hooks/useRestApi.types';
import { DdaUserData } from 'App.types';
import { useParams } from 'react-router';
import { Typography } from '@material-ui/core';

interface DdaUsersResp extends Omit<UseRestApiResult, 'data'> {
  data: {
    items: DdaUserData[];
  };
}

interface DdaUserUIProps extends DdaUserData {
  secret?: string;
}

export const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%',
    },
    tableHead: {
      height: '100%',
    },
    visible: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    idCell: {
      width: '50%',
    },
    userNameCell: {
      width: '40%',
    },
    passwordCell: {
      width: '10%',
    },
    heading: {
      marginBottom: '14px',
    },
    section: {
      marginTop: '24px',
    },
  })
);

// Loosely typing this, so we can use <ButtonBase> props.
const LooseIconButton = IconButton as any;

export const DdaDefinitionsUsers: React.FC = (props) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const [userName, setUserName] = useState<string | null>('');
  const [ddaUsers, setDdaUsers] = useState<{ items: DdaUserData[] }>({ items: [] });
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { data, error, loading } = useRestApi(
    apiUrl('lct', `dda/${tenantId}/users`)
  ) as DdaUsersResp;
  const { data: secretResp, fetch: getSecret } = useRestApi(
    apiUrl('lct', `dda/${tenantId}/secrets/${userName}`),
    {
      manual: true,
    }
  );

  let content: ReactElement = <></>;

  useEffect(() => {
    setDdaUsers(data);
  }, [data]);

  useEffect(() => {
    if (userName) {
      getSecret();
    }
  }, [userName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (secretResp) {
      viewPassword();
    }
  }, [secretResp]); // eslint-disable-line react-hooks/exhaustive-deps

  const loader = () => (
    <CircularProgress
      ariaLabel={t('global.loadingIndicator.loading').toString()}
      data-testid="loading-dda-user"
    />
  );

  const erroMessage = () => (
    <Typography data-testid="dda-user-info-error">{t('global.fetchError')}</Typography>
  );

  const noDdaUserMessage = () =>
    (content = <Typography data-testid="dda-user-list-no-data">{t('ddaList.noData')}</Typography>);

  const viewPassword = async () => {
    try {
      setDdaUsers((userData) => {
        return {
          items: userData?.items.map((user: DdaUserUIProps) => {
            const updateUser = { ...user };
            if (updateUser.userName === userName) {
              updateUser.isShowSecret = true;
              updateUser.secret = secretResp?.secret ?? '';
            }
            return updateUser;
          }),
        };
      });
    } catch (error) {
      console.error('error showing password');
    }
  };

  const userTable = (ddaUser: DdaUserUIProps[]) => {
    return (
      <Table className={classes.table} data-testid={'dda-user-datatable'}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell className={classes.idCell}>{t('ddaUser.userId')}</TableCell>
            <TableCell className={classes.userNameCell}>{t('ddaUser.userName')}</TableCell>
            <TableCell className={classes.passwordCell}>{t('ddaUser.password')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ddaUser.map((user: DdaUserUIProps) => {
            return (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.userName}</TableCell>
                <TableCell className={classes.visible}>
                  {!user.isShowSecret ? (
                    <LooseIconButton
                      data-testid={'dda-user-password-btn'}
                      onClick={() => setUserName(user.userName)}
                      component={Link}
                    >
                      <Visible />
                    </LooseIconButton>
                  ) : (
                    <Typography variant="subtitle1" data-testid="dda-user-password-value">
                      {user?.secret ?? ''}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  if (error) {
    content = erroMessage();
  } else if (loading) {
    content = loader();
  } else if (!ddaUsers?.items.length) {
    content = noDdaUserMessage();
  } else {
    content = userTable(ddaUsers.items);
  }

  return (
    <div data-testid="dda-users-section" className={classes.section}>
      <CardHeader
        title={t('ddaUser.pageTitle')}
        titleTypographyProps={{
          color: 'textSecondary',
          component: 'h3' as any,
          variant: 'h4',
        }}
        className={classes.heading}
      />
      {content}
    </div>
  );
};
