import React from 'react';
import {
  JobExecutionDto,
  ExecutionDetailResponseBody,
  ListExecutionsResponseBody,
} from '@lct-jobs/core';
import { useSnackbar } from 'hooks/useSnackbar';
import { apiUrl } from '../../utils/apiUrl';
import useRestApi from '../../hooks/useRestApi';
import { useTenantContext } from '../TenantContext';

export interface JobExecutionContextProps {
  readonly tenantId: string;
  readonly executions: JobExecutionDto[];
  readonly refresh: () => void;
  readonly loadMore: () => void;
  readonly nextPageToken?: string;
  readonly loading: boolean;
  readonly execution?: ExecutionDetailResponseBody;
  readonly setExecutionId: (id?: string) => void;
  readonly setTenantId: (tenantId: string) => void;
}

export const JobExecutionContext = React.createContext<JobExecutionContextProps>({
  tenantId: '',
  executions: [],
  refresh: () => {
    throw new Error('JobExecutionContextProvider not found');
  },
  loadMore: () => {
    throw new Error('JobExecutionContextProvider not found');
  },
  loading: false,
  setExecutionId: () => {
    throw new Error('JobExecutionContextProvider not found');
  },
  setTenantId: () => {
    throw new Error('JobExecutionContextProvider not found');
  },
});

export const useJobExecutionContext = () => React.useContext(JobExecutionContext);

export const JobExecutionContextProvider: React.FunctionComponent = (props) => {
  const { children } = props;
  const tenantContext = useTenantContext();
  const [nextPageToken, setNextPageToken] = React.useState<string | undefined>(undefined);
  const [siteNextPageToken, setSiteNextPageToken] = React.useState<string | undefined>(undefined);
  // eslint-disable-next-line prefer-const
  let [executionId, setExecutionId] = React.useState<string | undefined>(undefined);
  const [tenantId, setTenantId] = React.useState<string>(tenantContext.tenantId);

  const executionApi = useRestApi<ListExecutionsResponseBody>(
    apiUrl('lct', `tenants/${tenantContext.tenantId}/jobManager/executions`),
  );

  const childJobExecutions = useRestApi<ListExecutionsResponseBody>(
    apiUrl('lct', `tenants/${tenantContext.tenant?.tenantChildren?.[0]}/jobManager/executions`),
  );

  const executionDetailApi = useRestApi<ExecutionDetailResponseBody>(
    apiUrl('lct', `tenants/${tenantId}/jobManager/executions/${executionId}`),
    { manual: true },
  );

  const { error: executionDetailError } = executionDetailApi;
  let { data: execution } = executionDetailApi;

  const { enqueueSnackbar } = useSnackbar();

  const { data: executionData, error } = executionApi;
  const { data: siteExecutionData } = childJobExecutions;

  const [executions, setExecutions] = React.useState<JobExecutionDto[]>([]);

  const refresh = () => {
    setExecutions([]);
    setNextPageToken(undefined);
    setSiteNextPageToken(undefined);
    if (!executionId) {
      executionApi.fetch({
        method: 'GET',
      });
    }
    if (executionId) {
      executionDetailApi.fetch();
    }
    if (
      tenantContext.tenant?.tenantChildren?.[0] &&
      tenantContext.tenant.tenantType === 'Learn' &&
      !executionId
    ) {
      childJobExecutions.fetch();
    }
  };

  const loadMore = () => {
    executionApi.fetch({
      method: 'GET',
      params: {
        nextPageToken,
      },
    });
    childJobExecutions.fetch({
      method: 'GET',
      params: {
        siteNextPageToken,
      },
    });
  };

  const context: JobExecutionContextProps = {
    tenantId: tenantContext.tenantId,
    executions,
    refresh,
    loadMore,
    nextPageToken,
    loading: executionApi.loading || childJobExecutions.loading || executionDetailApi.loading,
    execution,
    setExecutionId: (id) => {
      setExecutionId(id);
      if (!id) {
        execution = undefined;
        executionId = undefined;
      }
    },
    setTenantId: (id) => {
      setTenantId(id);
    },
  };

  React.useEffect(() => {
    if (executionData?.executions || siteExecutionData?.executions) {
      setExecutions((prevExecutions) => {
        const newExecutions = [
          ...(executionData?.executions ?? []),
          ...(siteExecutionData?.executions ?? []),
        ];
        const newIds = newExecutions.map((e) => e.executionId);
        const combinedExecutions = [
          ...prevExecutions.filter((e) => !newIds.includes(e.executionId)),
          ...newExecutions,
        ];
        combinedExecutions.sort(
          (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
        );
        return combinedExecutions;
      });
    }
    if (executionApi.data) {
      setNextPageToken(executionApi.data.nextPageToken);
    }
    if (childJobExecutions.data) {
      setSiteNextPageToken(childJobExecutions.data.nextPageToken);
    }
    executionApi.data = undefined;
  }, [executionData, executionApi.data, siteExecutionData, childJobExecutions.data]);

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to load Job Executions: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  React.useEffect(() => {
    if (executionDetailError) {
      enqueueSnackbar(`Failed to load Job Executions: ${executionDetailError.message}`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, executionDetailError]);

  return <JobExecutionContext.Provider value={context}>{children}</JobExecutionContext.Provider>;
};
