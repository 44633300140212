import React, { useEffect, useState } from 'react';
import { useRestApi } from 'hooks/useRestApi';
import { TenantData } from 'App.types';
import { UseRestApiResult } from 'hooks/useRestApi.types';
import { apiUrl } from 'utils/apiUrl';

export interface TenantContextProps {
  fetch?: UseRestApiResult['fetch'];
  loading: boolean;
  tenant?: Partial<TenantData>;
  error?: Error;
  tenantId: string;
  parentTenant?: Partial<TenantData>;
  parentLoading: boolean;
  parentTenantError?: Error;
}

export interface TenantContextProviderProps {
  tenantId: string;
}

export const TenantContext = React.createContext<TenantContextProps>({
  loading: true,
  parentLoading: true,
  tenantId: 'GLOBAL',
});

export const useTenantContext = () => React.useContext(TenantContext);

export const TenantContextProvider: React.FunctionComponent<TenantContextProviderProps> = (
  props,
) => {
  const { children, tenantId } = props;
  const { data, error, loading, ...rest } = useRestApi(apiUrl('tenancy', `tenants/${tenantId}`));
  const {
    data: parentData,
    error: parentTenantError,
    loading: parentLoading,
    fetch: fetchParentTenant,
  } = useRestApi(apiUrl('tenancy', `tenants/${data?.parentTenant}`), { manual: true });

  const [parentTenant, setParentTenant] = useState<Partial<TenantData> | undefined>(undefined);

  useEffect(() => {
    if (data?.parentTenant) {
      fetchParentTenant().then((response) => {
        setParentTenant(response.data);
      });
    } else {
      setParentTenant(undefined);
    }
  }, [data, fetchParentTenant]);

  const context: TenantContextProps = {
    tenantId,
    loading,
    parentLoading,
    error,
    parentTenantError,
    tenant: data,
    parentTenant,
    ...rest,
  };

  return <TenantContext.Provider value={context}>{children}</TenantContext.Provider>;
};

export default TenantContextProvider;
