import React from 'react';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import { JobFormCard } from './basic/JobFormCard';
import { ExecutionDetailResponseBody } from '@lct-jobs/core';

export interface JobOutputProps {
  jobExecution?: ExecutionDetailResponseBody;
}

export const JobOutputBox: React.FC<JobOutputProps> = (props) => {
  const execution = props.jobExecution;

  return (
    <JobFormCard
      title="Job Output"
      schema={execution?.jobConfig.outputSchema ?? {}}
      uiSchema={execution?.jobConfig.outputUiSchema}
      formData={execution?.jobOutput}
    />
  );
};
