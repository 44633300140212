import moment from 'moment/moment';
import { Chip, ListItemIcon } from '@bb-ui/react-library';
import React from 'react';
import { OpenInNewWindow } from '@bb-ui/icons/dist/small';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { Faster, Slower, User, People } from '@bb-ui/icons/dist/medium';
import { useHistory } from 'react-router-dom';
import { useJobExecutionContext } from '../../../contexts/JobExecutionContext';
import { JobCardList } from './basic/JobCardList';
import { JobStatusIcon } from '../../JobStatusIcon/JobStatusIcon';
import { ExecutionDetailResponseBody } from '@lct-jobs/core';

export interface ExecutionPropsBox {
  jobExecution?: ExecutionDetailResponseBody;
  detailsLoading?: boolean;
  siteDetailsLoading?: boolean;
}

export const ExecutionBox: React.FC<ExecutionPropsBox> = (props) => {
  const history = useHistory();
  const execution = props.jobExecution;
  const loadingDetails = props.detailsLoading;
  const siteDetailsLoading = props.siteDetailsLoading;
  const startDate = execution?.startDate ? moment(execution.startDate) : undefined;
  const endDate = execution?.endDate ? moment(execution.endDate) : undefined;

  return (
    <JobCardList
      title="Execution Information"
      loading={loadingDetails || siteDetailsLoading}
      subtitle={execution?.executionId ? `Execution ID: ${execution.executionId}` : ''}
      actions={[
        execution?.traceId && (
          <Chip
            label="View X-Ray Trace"
            onClick={() =>
              window.open(
                `https://console.aws.amazon.com/cloudwatch/home?region=us-east-1#xray:traces/${execution?.traceId}`,
                '_blank',
                'noopener noreferrer',
              )
            }
            icon={<OpenInNewWindow />}
          />
        ),
      ]}
    >
      <ListItem>
        <ListItemIcon>
          <JobStatusIcon status={execution?.status} />
        </ListItemIcon>
        <ListItemText primary="Status" secondary={execution?.status} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Slower />
        </ListItemIcon>
        <ListItemText primary="Start Time" secondary={startDate?.fromNow()} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Faster />
        </ListItemIcon>
        <ListItemText primary="End Time" secondary={endDate?.fromNow() ?? 'Not Reported'} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <User />
        </ListItemIcon>
        <ListItemText primary="Created By" secondary={execution?.user ?? 'Unknown User'} />
      </ListItem>
      {execution?.parentExecution && (
        <ListItem
          button
          onClick={() =>
            history.push(
              `/tenants/${execution.parentExecution?.tenantId}/executions/${execution.parentExecution?.executionId}`,
            )
          }
        >
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText
            primary="Parent Execution"
            secondary={`Execution ID: ${execution.parentExecution.executionId}`}
          />
        </ListItem>
      )}
    </JobCardList>
  );
};
