import React, { ReactNode } from 'react';
import {
  Combobox,
  ComboboxOption,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Button,
  DialogActions,
  DefaultButton,
  PrimaryButton,
} from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import Form from '@rjsf/material-ui';
import { IJobConfig, JobDetailResponseBody, ProviderRegistration } from '@lct-jobs/core';
import { ISubmitEvent } from '@rjsf/core';
import useRestApi from '../../hooks/useRestApi';
import { apiUrl } from '../../utils/apiUrl';
import { useAppConfigContext } from 'contexts/AppConfigContext';

export interface JobSubmitEvent extends ISubmitEvent<any> {
  selectedProvider?: ProviderRegistration;
}

export interface RunDialogProps {
  job: IJobConfig;
  tenantId: string;
  closeDialog: () => void;
  submitJob: (s: JobSubmitEvent) => void;
}

type ProviderComboboxOption = ComboboxOption & {
  provider?: ProviderRegistration;
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      '& div[role="radiogroup"] label span:first-child': {
        width: '32px',
        height: '32px',
        borderRadius: '16px',
        '&:hover': {
          backgroundColor: theme.palette.background.b4,
        },
      },
    },
    loadingText: {
      paddingBottom: theme.spacing(1),
    },
  }),
);

const confirmationJobIds = [
  'DecommissionLearnInstaller',
  'DecommissionLearnInstance',
  'DeleteCname',
  'DeleteDDA',
  'DeleteDDAUser',
];

export const RunDialog: React.FC<RunDialogProps> = (props) => {
  const { t } = useTranslation();
  const { job } = props;
  const env = useAppConfigContext();
  const [open, setOpen] = React.useState<boolean>(true);
  const [providerOptions, setProviderOptions] = React.useState<ProviderComboboxOption[]>([]);
  const [selectedProvider, setSelectedProvider] = React.useState<
    ProviderComboboxOption | undefined
  >();
  const [updatedSchema, setUpdatedSchema] = React.useState<any>(job.inputSchema);
  const [formData, setFormData] = React.useState<any>({});
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const [pendingSubmit, setPendingSubmit] = React.useState<JobSubmitEvent | null>(null);
  const classes = useStyles(props);
  const { fetch, loading, data } = useRestApi<JobDetailResponseBody>(
    apiUrl('lct', `tenants/${props.tenantId}/jobManager/jobs/${props.job.jobId}/providers`),
  );

  const [isPolling, setIsPolling] = React.useState(true);
  const intervalIdRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    const pollInterval = 10000; // Poll every 10 seconds
    const pollDuration = 60000; // Stop polling after 1 minute (60000 ms)

    intervalIdRef.current = setInterval(async () => {
      if (isPolling && data?.outstandingAutoFillKeys && data?.outstandingAutoFillKeys?.length > 0) {
        await fetch();
      } else if (data?.outstandingAutoFillKeys && data.outstandingAutoFillKeys.length === 0) {
        setIsPolling(false);
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
      }
    }, pollInterval);

    const timeoutId = setTimeout(() => {
      setIsPolling(false);
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }, pollDuration);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      clearTimeout(timeoutId);
    };
  }, [isPolling, fetch, data]);

  React.useEffect(() => {
    if (!open) {
      props.closeDialog();
    }
  }, [open, props]);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const providers: ProviderRegistration[] = Object.values(data?.providers ?? {});
    const providerOpts = providers.map((provider: ProviderRegistration) => ({
      label: provider.displayName ?? `${provider.region} ${provider.deployment ?? ''}`,
      value: provider.providerId,
      provider,
    }));
    setProviderOptions(providerOpts);
  }, [data]);

  React.useEffect(() => {
    if (data?.resolvedAutoFillKeys) {
      const inputSchemaWithProperties = job.inputSchema as { properties: { [key: string]: any } };
      const newSchema = {
        ...inputSchemaWithProperties,
        properties: { ...inputSchemaWithProperties.properties },
      };

      Object.keys(data.resolvedAutoFillKeys).forEach((key) => {
        const resolvedKey = data.resolvedAutoFillKeys[key];

        Object.keys(newSchema.properties).forEach((propKey) => {
          const property = newSchema.properties[propKey];
          if (property.autofillKey === key) {
            if (resolvedKey.defaultValue) {
              property.default = resolvedKey.defaultValue;
            }
            if (resolvedKey.examples) {
              property.examples = resolvedKey.examples;
            }
            if (resolvedKey.enum) {
              property.enum = Object.keys(resolvedKey.enum);
              property.default = Object.keys(resolvedKey.enum)[0];
            }
          }
        });
      });

      setUpdatedSchema(newSchema);
    }
  }, [data, job.inputSchema]);

  const handleFormChange = ({ formData }: { formData: any }) => {
    setFormData((prevFormData: any) => {
      const newFormData = { ...prevFormData, ...formData };

      Object.keys(updatedSchema.properties).forEach((fieldKey) => {
        const property = updatedSchema.properties[fieldKey];
        const autofillKey = property.autofillKey;

        if (autofillKey && data?.resolvedAutoFillKeys[autofillKey]) {
          const resolvedKey = data.resolvedAutoFillKeys[autofillKey];
          if (resolvedKey.enum && formData[fieldKey]) {
            const selectedValue = formData[fieldKey];
            const enumValue = resolvedKey.enum[selectedValue];
            if (enumValue) {
              Object.keys(enumValue).forEach((enumFieldKey) => {
                newFormData[enumFieldKey] = enumValue[enumFieldKey];
              });
            }
          }
        }
      });

      return newFormData;
    });
  };

  const handleSubmit = (s: ISubmitEvent<any>) => {
    const requiresConfirmation = confirmationJobIds.includes(props.job.jobId);
    if (requiresConfirmation) {
      setPendingSubmit({
        ...s,
        selectedProvider: selectedProvider?.provider,
      });
      setConfirmOpen(true);
    } else {
      props.submitJob({
        ...s,
        selectedProvider: selectedProvider?.provider,
      });
      setOpen(false);
    }
  };

  const handleConfirmSubmit = () => {
    if (pendingSubmit) {
      props.submitJob(pendingSubmit);
      setConfirmOpen(false);
      setPendingSubmit(null);
      setOpen(false);
    }
  };

  const handleCancelSubmit = () => {
    setConfirmOpen(false);
    setPendingSubmit(null);
  };

  const environment = env.supportRedirectUrl?.match(/https:\/\/([^.]*)\./)?.[1];

  return (
    <>
      <Dialog id="run-dialog" open={open} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>{job.jobName}</DialogTitle>
        <DialogContent>
          {environment! === 'dev' && loading && (
            <Typography variant="body2" color="error" className={classes.loadingText}>
              {t('jobService.loadingProviders')}
            </Typography>
          )}
          {environment! === 'dev' ? (
            <Combobox
              floatingLabel
              id="combobox-single"
              label="Select a Provider"
              placeholder="Default Provider"
              strings={{
                announceOptionSelected(option: ComboboxOption): string {
                  return `${option.label}, selected.`;
                },
                announceOptionDeselected(option: ComboboxOption): string {
                  return `${option.label}, deselected.`;
                },
                announceValueCleared: 'All values deselected.',
                announceSearchResults(count: number, searchString: string): string {
                  return `${count} results found for "${searchString}". Use up and down arrow keys to navigate. Press Enter to select.`;
                },
                clearButtonLabel: 'Clear',
                searchLabel: 'Search',
                noResults(searchString: string): ReactNode {
                  return `No results found for "${searchString}"`;
                },
              }}
              options={providerOptions}
              onChange={setSelectedProvider}
              isLoading={loading}
              isClearable={true}
            />
          ) : null}
          <Form
            schema={updatedSchema}
            formData={formData}
            onChange={handleFormChange}
            onSubmit={handleSubmit}
            uiSchema={job.inputUiSchema}
            className={classes.form}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmOpen}
        onClose={handleCancelSubmit}
        BackdropProps={{
          style: { backgroundColor: 'rgba(0, 0, 0, .75)' },
        }}
      >
        <DialogTitle onClose={handleCancelSubmit}>
          {t('jobService.confirmSubmitTitle', { jobName: job.jobName })}
        </DialogTitle>
        <DialogActions>
          <DefaultButton onClick={handleCancelSubmit}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton onClick={handleConfirmSubmit}>{t('global.submit')}</PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
