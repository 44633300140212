import React from 'react';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useTenantContext } from 'contexts/TenantContext';

export interface ConfigurationContextProps {
  fetchAll?: () => void;
  configLoading: boolean;
  tenantId: string;
  configDomainsLoading: boolean;
  configuration?: Partial<Record<string, any>>;
  domains?: Partial<Record<string, string[]>>;
  configErrors?: Error;
  configDomainsError?: Error;
}

export const ConfigurationContext = React.createContext<ConfigurationContextProps>({
  configLoading: true,
  configDomainsLoading: true,
  tenantId: ''
});

export const useConfigurationContext = () => React.useContext(ConfigurationContext);

export const ConfigurationContextProvider: React.FunctionComponent = (props) => {
  const { children } = props;
  const { tenant, tenantId: currentTenantId } = useTenantContext();
  let tenantId = currentTenantId;
  const childTenantId = tenant?.tenantChildren?.[0]
  if (childTenantId) {
    tenantId = childTenantId
  }
  const {
    fetch: configFetch,
    data: config,
    error: configErrors,
    loading: configLoading,
  } = useRestApi(apiUrl('config', `tenants/${tenantId}/capabilityConfigs`));
  const {
    fetch: schemaFetch,
    data: configDomains,
    loading: configDomainsLoading,
    error: configDomainsError,
  } = useRestApi(apiUrl('config', `tenants/${tenantId}/capabilityConfigs/schemas`));

  const fetchAll = () => {
    configFetch();
    schemaFetch();
  };

  let context: ConfigurationContextProps = {
    tenantId: tenantId,
    configLoading,
    configErrors,
    configDomainsLoading,
    configDomainsError,
    fetchAll,
  };

  if (config && configDomains) {
    context = { configuration: config, domains: configDomains, ...context };
  }

  return <ConfigurationContext.Provider value={context}>{children}</ConfigurationContext.Provider>;
};

export default ConfigurationContextProvider;
